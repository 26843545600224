.auto-results-wrapper.auto-is-active {
  position: relative;
  margin-top: 10px;
}

.auto-results-wrapper {
  border: 0;
}

.loupe:before {
  top: 17px;
  bottom: auto;
}

.auto-clear {
  top: 12px;
  bottom: auto;
}

#auto-search-players-results {
  height: var(--slider-height);
}

.players-is-active {
  .slider-content {
    overflow: hidden;
  }
}

.auto-search-wrapper input {
  padding: 10px 12px;
}

// row autocomplete

.auto-footer {
  position: absolute;
  height: 32px;
  width: 100%;
  top: 0;
  background-color: red;
  color: #fff;
  padding: 5px 10px;
}

.auto-head {
  top: 0;
  text-transform: uppercase;
  align-items: center;
  color: var(--bs-white);
  background-color: var(--main-color-bg);
  font-size: 90%;
}

.auto-pr {
  padding-right: var(--touch-screen-players) !important;
}

.auto-row {
  position: relative;

  .auto-group {
    order: 1;
  }

  .auto-country {
    order: 3;
  }

  .auto-player-name {
    order: 2;
  }

  .auto-tee {
    order: 4;
  }

  .auto-thru {
    order: 5;
  }

  .auto-fav {
    order: 6;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1px;
    width: 5px;
    bottom: 0;
    background-color: var(--course-color);
  }
}

.auto-search-wrapper input {
  margin: 10px auto 0 auto;
  width: calc(100% - 20px);
  // margin: auto;
  display: flex;
}

.auto-clear {
  top: 11px;
  right: 8px;
}

.auto-search-wrapper ul li {
  padding: 0;
  font-size: 90%;
}

.auto-search-wrapper .border-top {
  border-color: var(--bs-gray-400) !important;
}

.prevent-close {
  cursor: auto !important;
}

[data-id-flightnumber] {
  cursor: pointer !important;
}
