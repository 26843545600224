.leaflet-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
}

.multi-course-layers {
  display: flex;
  max-width: 600px;
  margin: 20px auto 0;
  background: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.multi-course-wrapper {
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 730px) {
    flex-direction: column;
    gap: 0px;
  }
  gap: 15px;
  margin: auto;
  padding: 0 50px;
  justify-content: center;
  pointer-events: none;
}

.button-layer {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  color: #000;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-left: 1px solid var(--color-button-layer);
  border-left-width: 30px;
  transition: all 0.3s ease;
  &:hover {
    background: #fff;
  }
}
