.accordion {
  width: 100%;
  background-color: var(--bs-white);
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 10px;
  font-size: 18px;
  color: var(--bs-blue);
  border-bottom: 1px solid var(--bs-gray-500);
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.accordion:after {
  content: "\2193";
  float: right;
}

.accordion.is-open:after {
  content: "\2191";
}

.accordion:hover,
.accordion.is-open {
  background-color: var(--bs-gray-200);
}

.accordion-content {
  background-color: var(--bs-white);
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.accortion-list {
  margin-left: -13px;
}

.accortion-list {
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
    fill: var(--bs-gray-700);
  }
}
