.boda-box {
  cursor: pointer;
  height: 100%;
  left: 0;
  // opacity: 0;
  border: 20px solid red;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 999;
}
