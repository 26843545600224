.popup-info {
  position: fixed;
  background: var(--bs-gray-100);
  color: var(--bs-gray-900);
  width: calc(100% - 20px);
  height: max-content;
  max-width: 500px;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 1;
  margin: auto;
  opacity: 0;
  border-radius: 5px;
  padding: 10px;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.active-popup .popup-info {
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.popup-info {
  .popup-header {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .popup-close {
    position: absolute;
    top: 4px;
    right: 5px;
    padding: 0;
    border: 0;
    background: transparent;
    .icon {
      pointer-events: none;
      background: none;
      width: 30px;
      height: 30px;
      fill: var(--bs-gray-900);
    }
  }
}

.holes-played {
  margin-right: 15px;
}
