.card-leaflet {
  box-shadow: none;
  width: 100%;
  // max-width: 260px;
  @media (min-width: 768px) {
    max-width: 300px;
  }

  .card-body {
    padding: 0;

    p {
      margin-top: 5px;
      // font-size: 100%;
      &:empty {
        margin-top: 0;
      }
    }

    a {
      color: #fff;
    }

    video {
      max-width: 300px;
      width: 100%;
      max-height: 170px;
    }
  }

  .popup-content {
    font-size: 110%;
    border-top: 1px solid var(--bs-gray-300);
    padding-top: 10px;
    h4 {
      text-transform: uppercase;
    }

    thead th {
      font-weight: bold;
    }

    .top-five-country span {
      width: 19px;
      height: 19px;
    }
  }
}

// twitter
.card-twitter {
  a {
    color: #1d9bf0 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    padding-top: 3px;
    margin-left: -10px;
  }

  .logo {
    border-radius: 50%;
  }

  .video {
    max-width: 300px;
    width: 100%;
  }

  .social-links-highlights {
    min-width: 250px;
    margin-top: 12px;
    margin-bottom: -3px;
  }
}

// logo twitter remove margin top/bottom
.twitter-logo {
  margin: -15px 0 -10px;
}

// button for popup
.card-button {
  margin-top: 5px;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 11px;
  font-size: 1rem;
  line-height: 100%;
  background-color: var(--card-color-back, #000);
  color: var(--card-color-text, #fff) !important;
  transition: box-shadow ease 0.5s;

  &:hover {
    box-shadow: 0px 10px 12px -11px rgba(66, 68, 90, 1);
  }
}

.card-img-wrapper {
  max-height: 180px;
  position: relative;
  .card-button {
    position: absolute;
    bottom: 10px;
    right: -10px;
  }
}
