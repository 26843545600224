.hole-info {
  color: #192267;

  &-par strong {
    font-size: 0.9rem;
  }
  &-avg {
    color: var(--bs-gray-500);
  }
}
