:root {
  --active-sidebar: #fff700;
}

html {
  -webkit-tap-highlight-color: transparent;
  height: -webkit-fill-available;
  min-height: 100vh;
}

html,
body {
  height: 100%;
  width: 100vw;
}

body {
  margin: 0;
  @media (max-width: 992px) {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

main {
  flex: 1;
}
