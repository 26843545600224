.location-button {
  display: var(--has-touch-screen);
  background: var(--bs-white);
  border: 0;
  margin-top: 0;
  left: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.back-to-home,
.show-multi-course-layers {
  @extend .location-button;
  display: flex;
}
