.programme-header {
  background-color: #c1c1c1 !important;
  color: var(--bs-white);
  text-align: center;
}

.callendar-button {
  max-width: 30px;
  margin-left: auto;
  cursor: pointer;
  svg {
    border: 0;
    width: 30px;
    height: 30px;
    fill: gray;
  }
}

.column-time {
  @media (max-width: 480px) {
    padding: 0 5px;
  }
}

.parnter-logo {
  text-align: center;
  @media (max-width: 480px) {
    padding: 0;
  }
  img {
    max-height: 41px;
    @media (max-width: 460px) {
      max-width: 60px;
    }
  }
}

.programme-wrapper {
  width: 100%;
  vertical-align: baseline;
}
