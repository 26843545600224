.leaflet-bottom.leaflet-right {
  bottom: 18px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  @media (max-width: 660px) {
    bottom: 80px;
  }
  @media (max-width: 430px) {
    bottom: 65px;
  }
}

.leaflet-control-attribution {
  visibility: hidden;

  position: fixed;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 300px !important;
  height: auto !important;
  padding: 8px;
  @media (max-width: 599px) {
    overflow: visible;
  }

  @media (max-width: 768px) {
    max-width: max-content;
  }

  @media (min-width: 769px) {
    max-width: max-content;
  }

  right: 15px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    line-height: 100%;
  }

  z-index: -1;
  padding-right: 30px;
  padding-left: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-items: center;
}

.bottom-left-logo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  background: var(--bs-white);
}

.extra-icon {
  cursor: pointer;
  svg {
    pointer-events: none;
  }
}

// home button
.icon-home {
  // background: rgba(0, 0, 0, 0.4);
  // border-radius: 50px;
  // background: var(--bs-white);
  padding-left: 10px;
  @media (max-width: 932px) {
    padding-left: 0;
  }
  svg {
    display: inline-block;
    fill: rgba(255, 255, 255, 0.5);
    width: 120px;
    height: 30px;
  }
}

@media (max-width: 932px) {
  .eventhub-text {
    display: none;
  }
  // .icon-home {
  //   svg {
  //     fill: #000;
  //   }
  // }
}

.leaflet-bottom .leaflet-control {
  @media (max-width: 600px) {
    margin-bottom: 0;
  }
}

.home-eventhubhq {
  @media (max-width: 932px) {
    width: 40px;
  }
}

.content-information {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  &.leaflet-control {
    margin: 0 6px -5px;
  }
  &.extra-icon {
    margin: 0;
  }
}

// information icons
.home-eventhubhq {
  bottom: 10px;
  @media (max-width: 600px) {
    bottom: 80px;
  }
  @media (max-width: 430px) {
    bottom: 60px;
  }
}

.icon-infor {
  margin-right: 7px;

  &.information-active {
    svg {
      fill: #333;
    }
  }
  svg {
    display: inline-block;

    fill: rgba(255, 255, 255, 0.7);
    width: 25px;
    height: 25px;
  }
}
