.leaflet-popup-content-wrapper {
  max-width: 320px;
}

.popup-content {
  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
  }

  tbody th {
    font-weight: normal;
  }

  .table > :not(caption) > * > * {
    padding: 0.1rem 0.3rem;
  }

  thead > tr > :nth-child(1),
  tbody > tr > :nth-child(1) {
    padding: 0.1rem 0rem;
  }

  .top-five-country span {
    width: 15px;
    height: 15px;
  }
}

.leaflet-popup {
  // when element empty and have space
  // php create p element, reset margin
  p {
    margin: 0;
  }

  .card {
    border: 0;
  }
  .card-title {
    font-size: 1rem;
    font-weight: bold;
  }
  .popup-content {
    h4 {
      font-size: 0.9rem;
      font-weight: bold;
    }
  }
  .card-text {
    font-size: 100%;
  }

  table {
    margin-bottom: 0 !important;
  }

  .player-teetime {
    line-height: 120%;
  }
}

.leaflet-popup-content {
  margin: 13px 24px 13px 18px !important;
}

.leaflet-popup-content {
  &:has(.card-img),
  &:has(.card-video),
  &:has(.social-links-wrappers) {
    margin: 13px 18px !important;
  }
}

.group-popup {
  font-family: "Oswald", sans-serif;
  .leaflet-popup-content {
    min-width: 240px !important;
    max-width: 240px !important;
  }
}

.leaflet-popup:not(.group-popup) {
  font-family: "Inter", sans-serif !important;
}

// reset popup image

.leaflet-popup-content {
  margin: 13px 18px;
}

.card-img-top {
  margin-top: -15px;
  margin-left: -19px;
  margin-right: -19px;
  min-width: 320px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card-img {
  margin-top: -14px;
  margin-left: -19px;
  margin-right: -19px;
  min-width: 320px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 180px;
  // aspect-ratio: 16/10;
  background-repeat: no-repeat !important;
  background-position: 100% 100% !important;
  background-size: 100% 100% !important;
}

.card-video {
  margin-top: -14px;
  margin-left: -19px;
  margin-right: -19px;
  min-width: 320px;

  video {
    min-width: 100%;
    min-height: 180px;
    object-fit: fill;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
}

.leaflet-container a.leaflet-popup-close-button {
  background-color: #fff;
  border-radius: 12px;
}
