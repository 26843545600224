.sidebar-flags {
  svg {
    fill: var(--active-sidebar);
  }
  .menu-text {
    color: var(--active-sidebar);
  }
}

.flags-is-removed {
  .sidebar-flags {
    svg {
      fill: var(--bs-white);
    }
    .menu-text {
      color: var(--bs-white);
    }
  }
}

.favorite {
  display: none;
}

.favorite-player-on-map {
  background-color: #f7a001 !important;

  svg {
    fill: var(--bs-white);
  }

  .first {
    display: none;
  }
  .favorite {
    display: block;
  }
}
