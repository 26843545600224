#map {
  --heightHeader: var(--header-height, 76px);
  @media (max-width: 991px) {
    --heightHeader: calc(var(--header-height) + var(--watch-partner, 0px));
  }

  position: fixed;
  top: var(--header-height);
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100% - var(--heightHeader, 76px));
  height: calc(100% - var(--heightHeader, 76px));

  background: var(--bs-white);

  @media (max-width: 991px) {
    top: auto;
  }
}

@media (max-width: 991px) {
  .bg-dark {
    display: none;
  }
}

.map-container {
  overflow: hidden;
}

.maptiler-element {
  position: absolute;
  left: 20px;
  bottom: 5px;
  z-index: 999;
}

.leaflet-control-attribution a svg {
  display: none !important;
}
