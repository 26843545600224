// data in location bottom left
.dev-data-locator {
  font-size: 11px;
  padding: 2px 5px;
  background-color: #fff;
  position: fixed;
  bottom: 2px;
  left: 2px;
  z-index: 999;
}
